import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ApiService from "../../../Services/ApiService";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import { FormControl as MuiFormControl } from "@material-ui/core"; // Renamed MuiFormControl
import ReactQuill from "react-quill";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl } from "@mui/material";
import { useSnackbar } from 'notistack';
import environment from "../../../Environment/environment";
const BannerEdit = ({ onCancel, dataSource }) => {
  const { BASE_URL } = environment;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValue, setSelectedValue] = React.useState(dataSource.Position);
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    [{ image: "" }], // Add image button to toolbar
    ["clean"],
  ];
  const [imageFile, setimageFile] = useState(null); // State for icon file
  const [mobileFile, setmobileFile] = useState(null); // State for banner file
  const [blurFile, setBlurFile] = useState(null); // State for blur image file
  const [imageError, setimageError] = useState(null);
  const [mobileError, setmobileError] = useState(null);
  const [blurError, setBlurError] = useState(null);
  const  [pageOptions, setPageOptions] = useState([])

  const [formData, setFormData] = useState({
    Title: dataSource.Title,
    Type: dataSource.Type,
    Position: dataSource.Position,
    Page: dataSource.Page,
    ImageUrl: dataSource.ImageUrl,
    MobileImageUrl: dataSource.MobileImageUrl,
    BlurImageUrl: dataSource.BlurImageUrl,
    Description: dataSource.Description,
    SourceUrl: dataSource.SourceUrl,
    Status: dataSource.Status,
    IsActive: dataSource.IsActive == true ? "Active" : "Inactive",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
 
  const reviewOptions= [
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "published", viewValue: "Published" },

   
  ];
  const typeOptions = [
    { value: "image", viewValue: "Image" },
    { value: "youtube", viewValue: "YouTube" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange1 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      Description: content,
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
    getPages()
  }, []);
  const getPages = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const response = await ApiService.getAll("sdmcontentpages/getpagemaster");
      if (response && response) {
        setPageOptions(response);
       
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);
    const updatedFormData = {
      ...formData,
      Id: dataSource.Id,
      CreatedOn: dataSource.CreatedOn,
      CreatedBy: dataSource.CreatedBy,
      ModifiedBy: userData.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: formData.IsActive == "Active" ? true : false,
    };
    if (imageFile) {
      updatedFormData.ImageUrl = imageFile.name;
    }

    if (mobileFile) {
      updatedFormData.MobileImageUrl = mobileFile.name;
    }

    if (blurFile) {
      updatedFormData.BlurImageUrl = blurFile.name;
    }
    setFormData(updatedFormData);
    try {
      const res = await ApiService.put("sdmbanners", updatedFormData);
      if (res) {
        // // console.log('ssss',imageFile);
        const formData = new FormData();
        formData.append('ImageFile', imageFile); 
formData.append('BlurImageFile', blurFile); 
formData.append('MobileImageFile', mobileFile); 
formData.append('Id', dataSource.Id);
        try{
        const imgApi = await ApiService.put('sdmbanners/uploadimage',formData) 
        // if(imgApi){
        // handleCancel();
        // }
        if (res.Error == false) {
          handleCancel();
          enqueueSnackbar('Edited Successfully!',{ variant: 'success', autoHideDuration: 3000 });
        } else {
            enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
        }
        } catch (error){
      console.error("Error updating toggle state:", error);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    // console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleTypeChange = (e) => {
    setSelectedValue(e.target.value);
    if(e.target.value === 'hero'){
      setBlurFile(null);
      setFormData((prevData) => ({
        ...prevData,
        BlurImageUrl: '',
      }));
      // console.log('er')
    }
    setFormData((prevData) => ({
      ...prevData,
      Position: e.target.value,
    }));
  };

  const controlProps = (item) => ({
    onChange: handleTypeChange,
    value: item,
    name: "Position",
    inputProps: { "aria-label": item },
  });

  const handleimageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the icon file
      if (selectedFile.size > 12 * 1024 * 1024) {
        setimageError("Icon size should not exceed 2MB.");
        setimageFile(null);
      } else if (
        ![
          "image/jpeg", "image/png", "image/bmp", "image/gif", 
          "video/mp4", "video/avi", "video/mov", "video/wmv"
        ].includes(selectedFile.type)
      ) {
        setimageError(
          "Only JPG, JPEG, PNG, BMP, MP4, AVI, MOV, WMV and GIF formats are supported."
        );
        setimageFile(null);
      } else {
        setimageFile(selectedFile);
        setimageError(null);
      }
    }
  };

  const handlemobileFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the banner file
      if (selectedFile.size > 8 * 1024 * 1024) {
        setmobileError("Banner size should not exceed 2MB.");
        setmobileFile(null);
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setmobileError(
          "Only JPG, JPEG, PNG, BMP, and GIF formats are supported."
        );
        setmobileFile(null);
      } else {
        setmobileFile(selectedFile);
        setmobileError(null);
      }
    }
  };

  const handleBlurFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the blur image file
      if (selectedFile.size > 2 * 1024 * 1024) {
        setBlurError("Blur image size should not exceed 2MB.");
        setBlurFile(null);
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setBlurError(
          "Only JPG, JPEG, PNG, BMP, and GIF formats are supported."
        );
        setBlurFile(null);
      } else {
        setBlurFile(selectedFile);
        setBlurError(null);
      }
    }
  };

  const clearimageFile = () => {
    setimageFile(null);
    setimageError(null);
  };

  const clearmobileFile = () => {
    setmobileFile(null);
    setmobileError(null);
  };

  const clearBlurFile = () => {
    setBlurFile(null);
    setBlurError(null);
  };
  const clearimageFileEdit = () => {

    setFormData((prevData) => ({
       ...prevData,
       ImageUrl: null,
     }));
     setimageError(null);
   };
   const clearmobileFileEdit = () => {

    setFormData((prevData) => ({
       ...prevData,
       MobileImageUrl: null,
     }));
     setmobileError(null);
   };
   const clearBlurFileEdit = () => {

    setFormData((prevData) => ({
       ...prevData,
       BlurImageUrl: null,
     }));
     setBlurError(null);
   };
  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Edit Banner</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
      <div className="Content">
          {/* <div className="displayFlex m-b">
            <div className="example-container">
              <TextField
                label="Title"
                name="Title"
                value={formData.Title}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Type *</InputLabel>
                <Select
                  name="Type"
                  value={formData.Type}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {typeOptions.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
            <div className="example-container m-b">
              <div className="typeLabel">Position</div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={selectedValue} // Set the value to selectedValue
                >
                  <FormControlLabel
                    {...controlProps("hero")}
                    sx={{
                      color: "#EE3823 !important'", // Change the color here
                      "&.Mui-checked": {
                        color: "#EE3823 !important'", // Change the color here for the checked state
                      },
                    }}
                    value="hero"
                    control={<Radio />}
                    label="Hero"
                  />
                  <FormControlLabel
                    {...controlProps("mask")}
                    sx={{
                      color: "#EE3823 !important'", // Change the color here
                      "&.Mui-checked": {
                        color: "#EE3823 !important'", // Change the color here for the checked state
                      },
                    }}
                    value="mask"
                    control={<Radio />}
                    label="Mask"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div> */}
          <div className="displayFlex">
            <div className="example-container p-b-29">
              <div className="checkbox-lable m-24">
                Image *
                <span className="cursor imgInfoIcon">
                  <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                    <InfoIcon className="Icon-style svg-info " />
                  </Tooltip>
                </span>
              </div>
              <div className="disflex">
                <div>
                  <label htmlFor="imageFileInput" className="upload-file-label">
                    Choose Files
                  </label>
                  <input
                    className="upload-file"
                    type="file"
                    id="imageFileInput"
                    onChange={handleimageFileChange}
                  />
                </div>
                <span>
                  {imageFile ? (
                    <a onclick="event.preventDefault();" className="uploaded-file-link">
                      {imageFile.name}
                    </a>
                  ) :
                  <a href={BASE_URL+'/banners/'+formData.ImageUrl} target="_blank" className="uploaded-file-link">
                      {formData.ImageUrl}
                    </a>
                  }
                  {imageFile ? (
                    <CloseIcon
                      className="closeUpload cursor"
                      onClick={clearimageFile}
                    />
                  ) :
                  formData.ImageUrl ?
                  <CloseIcon
                  className="closeUpload cursor"
                  onClick={clearimageFileEdit}
                />
                : null
                }
                </span>
              </div>
              {imageError && <div className="imgError">{imageError}</div>}
              <p className="imgInfo">
                [Recommended for this image pixel width(200) x height(150)]
              </p>
            </div>

            <div className="example-container p-b-29">
              <div className="checkbox-lable m-24">
                Mobile Image *
                <span className="cursor imgInfoIcon">
                  <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                    <InfoIcon className="Icon-style svg-info " />
                  </Tooltip>
                </span>
              </div>
              <div className="disflex">
                <div>
                  <label
                    htmlFor="mobileFileInput"
                    className="upload-file-label"
                  >
                    Choose Files
                  </label>
                  <input
                    className="upload-file"
                    type="file"
                    id="mobileFileInput"
                    onChange={handlemobileFileChange}
                  />
                </div>
                <span>
                  {mobileFile ? (
                    <a onclick="event.preventDefault();" className="uploaded-file-link">
                      {mobileFile.name}
                    </a>
                  ) : 
                  <a href={BASE_URL+'/banners/'+formData.MobileImageUrl} target="_blank" className="uploaded-file-link">
                  {formData.MobileImageUrl}
                </a>
                
                }
                  {mobileFile ? (
                    <CloseIcon
                      className="closeUpload cursor"
                      onClick={clearmobileFile}
                    />
                  ) : formData.MobileImageUrl ? 
                  <CloseIcon
                  className="closeUpload cursor"
                  onClick={clearmobileFileEdit}
                />
                : null
                }
                </span>
              </div>
              {mobileError && <div className="imgError">{mobileError}</div>}
              <p className="imgInfo">
                [Recommended for this image pixel width(200) x height(150)]
              </p>
            </div>
            {selectedValue === "mask" && (
              <div className="example-container p-b-29">
                <div className="checkbox-lable m-24">
                  Blur Image *
                  <span className="cursor imgInfoIcon">
                    <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                      <InfoIcon className="Icon-style svg-info " />
                    </Tooltip>
                  </span>
                </div>
                <div className="disflex">
                  <div>
                    <label
                      htmlFor="blurFileInput"
                      className="upload-file-label"
                    >
                      Choose Files
                    </label>
                    <input
                      className="upload-file"
                      type="file"
                      id="blurFileInput"
                      onChange={handleBlurFileChange}
                    />
                  </div>
                  <span>
                    {blurFile ? (
                      <a onclick="event.preventDefault();" className="uploaded-file-link">
                        {blurFile.name}
                      </a>
                    )
                  : 
                  <a href={BASE_URL+'/banners/'+formData.BlurImageUrl} target="_blank" className="uploaded-file-link">
                        {formData.BlurImageUrl}
                      </a>
                  }
                    {blurFile ? (
                      <CloseIcon
                        className="closeUpload cursor"
                        onClick={clearBlurFile}
                      />
                    ) : formData.BlurImageUrl ? 
                    <CloseIcon
                    className="closeUpload cursor"
                    onClick={clearBlurFileEdit}
                  /> : null
                  }
                  </span>
                </div>
                {blurError && <div className="imgError">{blurError}</div>}
                <p className="imgInfo">
                  [Recommended for this image pixel width(200) x height(150)]
                </p>
              </div>
            )}
          </div>
          {/* <div className="example-container m-b">
            <div className="checkbox-lable m-24">Description</div>
            <ReactQuill
              theme="snow"
              className="quill-editor multiSelectWidth"
              value={formData.Description}
              onChange={handleChange1}
              modules={{
                toolbar: toolbarOptions,
              }}
              placeholder={"Write something"}
            />
          </div> */}
          <div className="displayFlex m-b">
          <div className="example-container">
              <TextField
                label="Source Url"
                name="SourceUrl"
                value={formData.SourceUrl}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Page</InputLabel>
                <Select
                  name="Page"
                  value={formData.Page}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {pageOptions.map((page) => (
                    <MenuItem key={page.PageKey} value={page.PageKey}>
                      {page.PageName}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Review Status</InputLabel>
                <Select
                  name="Status"
                  value={formData.Status}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {reviewOptions.map((review) => (
                    <MenuItem key={review.value} value={review.value}>
                      {review.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
          
              </div>
              <div className="displayFlex m-b">
            <div className="example-container">
              <MuiFormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
          </div>

        
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Update
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BannerEdit;
